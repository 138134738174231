import React from "react";
import divider from "../../../assets/image/line6.png";
import Author from "@molecules/Author";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";
import { useOnScreen } from "../../../hooks/useOnScreen";

const Editors = ({ authors }) => {
  const [authorSection, authorsVisible] = useOnScreen({ threshold: 0.3 });
  const { t } = useTranslation();

  return (
    <motion.section
      ref={authorSection}
      initial={{ opacity: 0, y: 20 }}
      animate={authorsVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ type: "spring", stiffness: 20 }}
      className='container mb168'
    >
      <div className='row'>
        <div className='col-md-10 m-auto'>
          <p className='subtitleblog'>{t("CONTENT.AUTHORS")}</p>
          <div className='mb10'>
            <img src={divider} alt='divider' loading="lazy"  style={{ maxWidth: "100%" }} />
          </div>
          <div className='row'>
            {authors.map((el, index) => el.image && 
              <div key={index} className='col-md-4'>
                <Author
                  img={el.image.url}
                  name={el.name}
                  job={el.work}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Editors;
